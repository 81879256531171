import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import STRINGS, {
  TERM_OF_USE_LAST_MODIFIED_DATE,
} from "../../constants/TermsAndConditions";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import TermsTitleSection from "../../sections/TermsAndConditions/TermsTitleSection";
import ContentText from "../../components/ContentText";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <TermsTitleSection title="Terms of Use" />
      <ContentText
        content={STRINGS}
        dateContent={TERM_OF_USE_LAST_MODIFIED_DATE}
      />
      <Footer />
    </>
  );
};

const TermsAndConditions = () => {
  return (
    <>
      <Header />
      <PageLayout title="Terms of Use">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default TermsAndConditions;
