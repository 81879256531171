import React from "react";
import {Link} from "gatsby";
import ROUTES from "../routes";
import * as styles from "../index.module.scss"

export const TERM_OF_USE_LAST_MODIFIED_DATE = "Last Modified: 2022-01-19"

const STRINGS = [
    {
        title: "Acceptance of the Terms of Use",
        text: <>
            <p>
                These terms of use are entered into by and between You and Fearn Co LLC ("<strong>Fearn</strong>,"
                "<strong>we</strong>," or "<strong>us</strong>"). The following
                terms and conditions, together with any documents they expressly incorporate by reference (collectively,
                "<strong>Terms of Use</strong>"), govern your access to and use of fearn.co including any content,
                functionality,
                and services offered on or through fearn.co (the "<strong>Website</strong>"), whether as a guest or a
                registered user.
            </p>
            <p>
                Please read the Terms of Use carefully before you start to use the Website. <strong>By using the Website
                or by clicking
                to accept or agree to the Terms of Use when this option is made available to you, you accept and agree
                to be bound
                and abide by these Terms of Use and our Privacy Policy, <Link href={ROUTES.privacyPolicy}
                                                                           className={styles.link}>found at</Link>,
                incorporated
                herein by reference.</strong>
                If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the
                Website.
            </p>
            <p>
                This Website is offered and available to users who are 18 years of age or older and reside in the
                United States or any of its territories or possessions. By using this Website, you represent and warrant
                that you
                are of legal age to form a binding contract with Fearn and meet all of the foregoing eligibility
                requirements.
                If you do not meet all of these requirements, you must not access or use the Website.
            </p>
        </>,
    },
    {
        title: "Changes to the Terms of Use",
        text: <>
            <p>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
                effective immediately
                when we post them, and apply to all access to and use of the Website thereafter.
            </p>
            <p>
                Your continued use of the Website following the posting of revised Terms of Use means that you accept
                and agree to the changes.
                You are expected to check this page frequently so you are aware of any changes, as they are binding on
                you.
            </p>
        </>,
    },
    {
        title: "Accessing the Website and Account Security",
        text: <>
            <p>
                We reserve the right to withdraw or amend this Website, and any service or material we provide on the
                Website, in our sole discretion
                without notice. We will not be liable if for any reason all or any part of the Website is unavailable at
                any time or for any period.
                From time to time, we may restrict access to some parts of the Website, or the entire Website, to users,
                including registered users.
            </p>
            <p>
                You are responsible for both:
            </p>
            <p>
                Making all arrangements necessary for you to have access to the Website.
            </p>
            <p>
                Ensuring that all persons who access the Website through your internet connection are aware of these
                Terms of Use and comply with them.
            </p>
            <p>
                To access the Website or some of the resources it offers, you may be asked to provide certain
                registration details or other information.
                It is a condition of your use of the Website that all the information you provide on the Website is
                correct, current, and complete.
                You agree that all information you provide to register with this Website or otherwise, including, but
                not limited to, through the use
                of any interactive features on the Website, is governed by our <Link href={ROUTES.privacyPolicy}
                                                                                  className={styles.link}><em>Privacy
                Policy</em></Link>,
                and you consent to all actions
                we take with respect to your information consistent with our Privacy Policy.
            </p>
            <p>
                If you choose, or are provided with, a user name, password, or any other piece of information as part of
                our security procedures,
                you must treat such information as confidential, and you must not disclose it to any other person or
                entity. You also acknowledge
                that your account is personal to you and agree not to provide any other person with access to this
                Website or portions of it using
                your user name, password, or other security information. You agree to notify us immediately of any
                unauthorized access to or use of
                your user name or password or any other breach of security. You also agree to ensure that you exit from
                your account at the end of
                each session. You should use particular caution when accessing your account from a public or shared
                computer so that others are not
                able to view or record your password or other personal information.
            </p>
            <p>
                We have the right to disable any user name, password, or other identifier, whether chosen by you or
                provided by us, at any time in
                our sole discretion for any or no reason, including if, in our opinion, you have violated any provision
                of these Terms of Use.
            </p>
        </>,
    },
    {
        title: "Intellectual Property Rights",
        text: <>
            <p>
                The Website and its entire contents, features, and functionality (including but not limited to all
                information, software, text, displays, images, video, and audio, and the design, selection, and
                arrangement thereof) are owned by Fearn, its licensors, or other providers of such material and are
                protected by United States and international copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws.
            </p>
            <p>
                These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must
                not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
                republish, download, store, or transmit any of the material on our Website, except as follows:
            </p>
            <p>
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and
                viewing those materials
            </p>
            <p>
                You may store files that are automatically cached by your Web browser for display enhancement purposes.
            </p>
            <p>
                You may print or download one copy of a reasonable number of pages of the Website for your own personal,
                non-commercial use and not for further reproduction, publication, or distribution.
            </p>
            <p>
                If we provide desktop, mobile, or other applications for download, you may download a single copy to
                your computer or mobile device solely for your own personal, non-commercial use, provided you agree to
                be bound by our end user license agreement for such applications.
            </p>
            <p>
                You must not:
            </p>
            <p>
                Modify copies of any materials from this site.
            </p>
            <p>
                Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials
                from this site.
            </p>
            <p>
                You must not access or use for any commercial purposes any part of the Website or any services or
                materials available through the Website.
            </p>
            <p>
                If you print, copy, modify, download, or otherwise use or provide any other person with access to any
                part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately
                and you must, at our option, return or destroy any copies of the materials you have made. No right,
                title, or interest in or to the Website or any content on the Website is transferred to you, and all
                rights not expressly granted are reserved by Fearn. Any use of the Website not expressly permitted by
                these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other
                laws.
            </p>
        </>
    },
    {
        title: "Trademarks",
        text: <>
            <p>
                The Fearn name, the Fearn logo, and all related names, logos, product and service names, designs, and
                slogans are trademarks of Fearn or its affiliates or licensors. You must not use such marks without the
                prior written permission of Fearn. All other names, logos, product and service names, designs, and
                slogans on this Website are the trademarks of their respective owners.
            </p>
        </>
    },
    {
        title: "Prohibited Uses",
        text: <>
            <p>
                You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree
                not to use the Website:
            </p>
            <p>
                In any way that violates any applicable federal, state, local, or international law or regulation
                (including, without limitation, any laws regarding the export of data or software to and from the US or
                other countries).
            </p>
            <p>
                For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing
                them to inappropriate content, asking for personally identifiable information, or otherwise.
            </p>
            <p>
                To transmit, or procure the sending of, any advertising or promotional material, including any "junk
                mail," "chain letter," "spam," or any other similar solicitation.
            </p>
            <p>
                To impersonate or attempt to impersonate Fearn, a Fearn employee, another user, or any other person or
                entity (including, without limitation, by using email addresses or screen names associated with any of
                the foregoing).
            </p>
            <p>
                To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or
                which, as determined by us, may harm Fearn or users of the Website, or expose them to liability.
            </p>
            <p>
                Additionally, you agree not to:
            </p>
            <p>
                Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere
                with any other party's use of the Website, including their ability to engage in real time activities
                through the Website.
            </p>
            <p>
                Use any robot, spider, or other automatic device, process, or means to access the Website for any
                purpose, including monitoring or copying any of the material on the Website.
            </p>
            <p>
                Use any manual process to monitor or copy any of the material on the Website, or for any other purpose
                not expressly authorized in these Terms of Use, without our prior written consent.
            </p>
            <p>
                Use any device, software, or routine that interferes with the proper working of the Website.
            </p>
            <p>
                Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
                technologically harmful.
            </p>
            <p>
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the
                server on which the Website is stored, or any server, computer, or database connected to the Website.
            </p>
            <p>
                Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
            </p>
            <p>
                Otherwise attempt to interfere with the proper working of the Website.
            </p>
        </>
    },
    {
        title: "Reliance on Information Posted",
        text: <>
            <p>
                The information presented on or through the Website is made available solely for general information
                purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance
                you place on such information is strictly at your own risk. We disclaim all liability and responsibility
                arising from any reliance placed on such materials by you or any other visitor to the Website, or by
                anyone who may be informed of any of its contents.
            </p>
            <p>
                This Website may include content provided by third parties, including materials provided by other users
                and third-party licensors. All statements and/or opinions expressed in these materials, and all articles
                and responses to questions and other content, other than the content provided by Fearn, are solely the
                opinions and the responsibility of the person or entity providing those materials. These materials do
                not necessarily reflect the opinion of Fearn. We are not responsible, or liable to you or any third
                party, for the content or accuracy of any materials provided by any third parties.
            </p>
        </>
    },
    {
        title: "Changes to the Website",
        text: <>
            <p>
                We may update the content on this Website from time to time, but its content is not necessarily complete
                or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under
                no obligation to update such material.
            </p>
        </>
    },
    {
        title: "Information About You and Your Visits to the Website",
        text: <>
            <p>
                All information we collect on this Website is subject to our <Link href={ROUTES.privacyPolicy}
                                                                                className={styles.link}>Privacy
                Policy</Link>. By
                using the Website, you consent to all actions taken by us with respect to your information in compliance
                with the Privacy Policy.
            </p>
        </>
    },
    {
        title: "Linking to the Website and Social Media Features",
        text: <>
            <p>
                You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our
                reputation or take advantage of it, but you must not establish a link in such a way as to suggest any
                form of association, approval, or endorsement on our part.
            </p>
            <p>
                Subject to the foregoing, you must not:
            </p>
            <p>
                Establish a link from any website that is not owned by you.
            </p>
            <p>
                Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site,
                for example, framing, deep linking, or in-line linking.
            </p>
            <p>
                Link to any part of the Website other than the homepage.
            </p>
            <p>
                Otherwise take any action with respect to the materials on this Website that is inconsistent with any
                other provision of these Terms of Use.
            </p>
            <p>
                You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We
                reserve the right to withdraw linking permission without notice.
            </p>
        </>
    }, {
        title: "Links from the Website",
        text: <>
            <p>
                If the Website contains links to other sites and resources provided by third parties, these links are
                provided for your convenience only. We have no control over the contents of those sites or resources,
                and accept no responsibility for them or for any loss or damage that may arise from your use of them. If
                you decide to access any of the third-party websites linked to this Website, you do so entirely at your
                own risk and subject to the terms and conditions of use for such websites.
            </p>
        </>
    }, {
        title: "Geographic Restrictions",
        text: <>
            <p>
                The owner of the Website is based in the State of Oregon in the United States. We provide this Website
                for use only by persons located in the United States. We make no claims that the Website or any of its
                content is accessible or appropriate outside of the United States. Access to the Website may not be
                legal by certain persons or in certain countries. If you access the Website from outside the United
                States, you do so on your own initiative and are responsible for compliance with local laws.
            </p>
        </>
    },
    {
        title: "Disclaimer of Warranties",
        text: <>
            <p>
                You understand that we cannot and do not guarantee or warrant that files available for downloading from
                the internet or the Website will be free of viruses or other destructive code. You are responsible for
                implementing sufficient procedures and checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
                site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
                LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
                OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
            </p>
            <p>
                YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR
                OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED
                ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER FEARN NOR ANY PERSON ASSOCIATED WITH FEARN MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
                THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT
                LIMITING THE FOREGOING, NEITHER FEARN NOR ANYONE ASSOCIATED WITH FEARN REPRESENTS OR WARRANTS THAT THE
                WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
                ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, FEARN HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
            </p>
            <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
        </>
    },
    {
        title: "Limitation on Liability",
        text: <>
            <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL FEARN, ITS AFFILIATES, OR THEIR LICENSORS,
                SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
                ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
                WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
                INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
                PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
                (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            </p>
            <p>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
        </>
    },
    {
        title: "Indemnification",
        text: <>
            <p>
                You agree to defend, indemnify, and hold harmless Fearn, its affiliates, licensors, and service
                providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
                suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to
                your violation of these Terms of Use or your use of the Website, including, but not limited to, any use
                of the Website's content, services, and products other than as expressly authorized in these Terms of
                Use, or your use of any information obtained from the Website.
            </p>
        </>
    },
    {
        title: "Governing Law and Jurisdiction",
        text: <>
            <p>
                All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom
                or related thereto (in each case, including non-contractual disputes or claims), will be governed by and
                construed in accordance with the internal laws of the State of Oregon without giving effect to any
                choice or conflict of law provision or rule (whether of the State of Oregon or any other jurisdiction).
            </p>
            <p>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website
                will be instituted exclusively in the federal courts of the United States or the courts of the State of
                Oregon, in each case located in the City of Portland and County of Multnomah, although we retain the
                right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your
                country of residence or any other relevant country. You waive any and all objections to the exercise of
                jurisdiction over you by such courts and to venue in such courts.
            </p>
        </>
    },
    {
        title: "Limitation on Time to File Claims",
        text: <>
            <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE
                WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE
                OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
        </>
    },
    {
        title: "Waiver and Severability",
        text: <>
            <p>
                No waiver by Fearn of any term or condition set out in these Terms of Use will be deemed a further or
                continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
                of Fearn to assert a right or provision under these Terms of Use will not constitute a waiver of such
                right or provision.
            </p>
            <p>
                If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to
                be invalid, illegal, or unenforceable for any reason, such provision will be eliminated or limited to
                the minimum extent such that the remaining provisions of the Terms of Use will continue in full force
                and effect.
            </p>
        </>
    },
    {
        title: "Entire Agreement",
        text: <>
            <p>
                The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Fearn
                regarding the Website and supersede all prior and contemporaneous understandings, agreements,
                representations, and warranties, both written and oral, regarding the Website.
            </p>
        </>
    },
    {
        title: "Your Comments and Concerns",
        text: <>
            <p>
                This website is operated by Fearn Co LLC.
            </p>
            <p>
                All feedback, comments, requests for technical support, and other communications relating to the Website
                should be directed to: contact@fearn.co
            </p>
        </>
    },
];

export default STRINGS;
